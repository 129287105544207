<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-container>
      <v-layout wrap justify-center v-if="joblist" py-16>
        <v-flex xs12 text-left v-if="joblist.length > 0">
          <v-layout wrap>
            <v-flex md9 lg9 px-5 px-lg-12 class="hidden-sm-and-down">
              <v-layout
                wrap
                class="cwhite popsemibold"
                style="background-color: #005f32"
                pa-3
              >
                <v-flex md4 lg4>
                  <span>JOB TITLE</span>
                </v-flex>
                <v-flex md4 lg4>
                  <span>CATEGORY</span>
                </v-flex>
                <v-flex md4 lg4>
                  <span>LOCATION</span>
                </v-flex>
                <v-flex xs12>
                  <v-spacer></v-spacer>
                </v-flex>
              </v-layout>
              <v-layout wrap style="font-size: 15px" class="popregular cblack">
                <v-flex xs12 py-4 v-for="(item, i) in joblist" :key="i">
                  <v-layout wrap text-left>
                    <v-flex md4 lg4 pr-3>
                      <a :href="'/JobDetails?id=' + item._id">
                      <span class="titles"> {{ item.name }}</span>
                      </a>
                    </v-flex>
                    <v-flex md4 lg4 pr-2>
                      <span>
                        {{ item.jobCategory.name }}
                      </span>
                    </v-flex>
                    <v-flex md4 lg4>
                      <v-layout wrap justify-center>
                        <v-flex xs6 text-left>
                          <span>
                            {{ item.location }}
                          </span>
                        </v-flex>
                        <v-flex xs6 text-right>
                          <!-- <v-btn plain text class="titles">Apply Now</v-btn> -->
                          <a :href="'/JobDetails?id=' + item._id">
                            <span class="titles">Apply Now</span>
                          </a>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 py-2>
                      <div style="border-bottom: 1px solid #828282"></div>
                      <!-- <v-divider style="border:1px solid #828282"></v-divider> -->
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- form mobile,sm,md -->
            <v-flex lg9 px-2 class="hidden-md-and-up">
              <v-layout wrap style="font-size: 15px" class="popregular cblack">
                <v-flex xs12 py-4 v-for="(item, i) in joblist" :key="i">
                  <v-layout
                    wrap
                    text-center
                    style="border: 1px solid #828282"
                    pa-4
                  >
                    <v-flex xs12 py-2>
                      <a :href="'/JobDetails?id=' + item._id">
                      <span class="titles"> {{ item.name }}</span>
                      </a>
                    </v-flex>
                    <v-flex xs12 py-2>
                      <span>
                        {{ item.jobCategory.name }}
                      </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap justify-center>
                        <v-flex xs12 text-center py-2>
                          <span>
                            {{ item.location }}
                          </span>
                        </v-flex>
                        <v-flex xs12 text-center>
                          <v-btn
                            dark
                            tile
                            class="text-capitalize"
                            color="#005f32"
                            :to="'/JobDetails?id=' + item._id"
                            >Apply Now</v-btn
                          >
                          <!-- <a :href="'/JobDetails?id='+item._id">
                            <span class="titles">Apply Now</span>
                          </a> -->
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>

            <!-- end mobile -->

            <v-flex md3 lg3 class="popregular cblack" v-if="catList">
              <v-layout wrap>
                <v-flex xs12 pt-8 pt-md-0>
                  <span style="font-size: 32px">CATEGORY</span>
                </v-flex>
                <v-flex xs12>
                  <v-layout wrap>
                    <v-flex
                      xs12
                      py-3
                      px-2
                      class="onhov"
                      style="cursor: pointer"
                      @click="getData()"
                    >
                      <span style="font-size: 13.5px"
                        >All Categories

                        <!-- <br />
                      <br />
                      <v-divider ></v-divider> -->
                      </span>
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                    <v-flex xs12 v-for="(cat, c) in catList" :key="c">
                      <v-layout wrap>
                        <v-flex
                          xs12
                          class="onhov"
                          py-3
                          px-2
                          style="cursor: pointer"
                          @click="getData(cat._id)"
                        >
                          <span style="font-size: 14px">
                            {{ cat.name }}
                          </span>
                        </v-flex>
                        <v-flex xs12>
                          <v-divider></v-divider>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex v-else xs12 align-self-center text-center py-16>
          <span class="popregular small cblack">
            Currently we have no openings available!
          </span>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      joblist: null,
      catList: null,
    };
  },
  beforeMount() {
    this.getData();
    this.getCategory();
  },
  methods: {
    getData(cat) {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/job/title/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          jobCategory: cat,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.joblist = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getCategory() {
      this.appLoading = true;
      axios({
        url: "/job/category/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.catList = response.data.data;
          // this.catList.unshift('All')
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.titles {
  color: #005f32 !important;
}
.titles:hover {
  color: #e27826 !important;
}
.onhov {
  background-color: #ffffff !important;
}
.onhov:hover {
  background-color: #005f32 !important;
  color: #ffffff !important;
}
</style>
