<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <Banner />
      </v-flex>
<!-- 
      <v-flex xs12 pt-5>
        <a href="https://wti.org.in/wp-content/uploads/pdf.pdf" target="_blank">
          <v-img
            max-height="320"
            max-width="1990"
            src="./../../assets/Images/ASA.jpg"
          ></v-img>
        </a>
      </v-flex> -->

      <v-flex xs12 text-left>
        <Jobs />
      </v-flex>
      <v-flex xs12 text-left>
        <Partners />
      </v-flex>
      <v-flex xs12 text-left pt-5>
        <Bottom />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
import Banner from "./banner";
import Jobs from "./jobs";
import Partners from "./partners";
import Bottom from "./bottom";

export default {
  components: {
    Banner,
    Jobs,
    Partners,
    Bottom,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dashboard: {},
    };
  },
  beforeMount() {
    // this.getData();
  },
  methods: {
    // getData() {
    //   this.appLoading = true;
    //   axios({
    //     method: "GET",
    //     url: "/dashboard/admin",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status == true) {
    //         this.dashboard = response.data.data;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       console.log(err);
    //     });
    // },
  },
};
</script>